import * as React from 'react';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'; // highlight-line
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../src/styles/global.css';
import '../../../src/styles/mdxslugPosts.css';

const BlogPost = ({ args, data, sourceInstanceNameProp }) => {
   const image = getImage(data.mdx.frontmatter.hero_image); // highlight-line

   // console.log(pageContext);
   // console.log({data});
   console.log('args', args);
   console.log('data', data);

   // const RoundedImageHolder = styled.div`
   //    display: flex;
   //    justify-content: center;
   //    background-color: red;
   // `;

   // const StyledImage = styled(GatsbyImage)`
   //    border-radius: 50%;
   // `;

   return (
      <Layout>
         <div className="contentArticle">
            {sourceInstanceNameProp}
            <br />
            <br />
            <div className="textCategory">{data.mdx.frontmatter.category}</div>
            <br />
            <div className="textTitle">{data.mdx.frontmatter.title}</div>
            <div className="textSubtitle">{data.mdx.frontmatter.date}</div>
            <br />
            {/* <div className="textImgCenter">
               <StaticImage
                  alt="StJoseph"
                  src="../images/grapes.jpeg"
                  width="250"
                  height="250"
                  imgStyle={{ borderRadius: '50%' }}
               />
            </div> */}
            <div className="centerImageMdxslugPosts">
               <GatsbyImage
                  image={image}
                  alt={data.mdx.frontmatter.hero_image_alt}
                  // className="testImageClass rounded-circle"
                  className="rounded-circle"
               />
            </div>
            <br />
            <br />
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
            <br />
         </div>

         {/* <div className="centerImageMdxslugPosts">
            <GatsbyImage
               image={image}
               alt={data.mdx.frontmatter.hero_image_alt}
               className="testImageClass rounded-circle"
            />
         </div> */}
      </Layout>
   );
};

// // highlight-start
// export const query = graphql`
//    query ($id: String) {
//       mdx(id: { eq: $id }) {
//          frontmatter {
//             category
//             title
//             date(formatString: "MMMM D, YYYY")
//             hero_image_alt
//             hero_image_credit_link
//             hero_image_credit_text
//             hero_image {
//                childImageSharp {
//                   gatsbyImageData(
//                      layout: CONSTRAINED
//                      width: 350
//                      height: 350
//                      transformOptions: { fit: CONTAIN, cropFocus: ATTENTION }
//                   )
//                }
//             }
//          }
//          body
//       }
//    }
// `;
// // highlight-end

// highlight-start
export const query = graphql`
   query ($id: String) {
      mdx(id: { eq: $id }) {
         frontmatter {
            category
            title
            date(formatString: "MMMM D, YYYY")
            hero_image_alt
            hero_image_credit_link
            hero_image_credit_text
            hero_image {
               childImageSharp {
                  gatsbyImageData(
                     layout: CONSTRAINED
                     width: 350
                     height: 350
                     transformOptions: { fit: COVER, cropFocus: ATTENTION }
                  )
               }
            }
         }
         body
         excerpt(pruneLength: 25)
      }
   }
`;
// highlight-end

export default BlogPost;

{
   /* <GatsbyImage
   image={image}
   alt={data.mdx.frontmatter.hero_image_alt}
   className="testImageClass"
   // imgStyle={{ borderRadius: '50%', height: 150, width: 150 }}
   imgStyle={{
      borderRadius: `50%`,
      height: 150,
      width: 150,
   }}
/>; */
}
